<template>
  <section class="w-72 mx-auto py-5 space-y-4">
    <div>
      <input v-model="current_password" type="password" class="shadow-sm focus:ring-base-500 focus:border-base-500 block w-full sm:text-sm border-gray-300 rounded-md h-10" :placeholder="loc.current_password" />
    </div>
    <div>
      <input v-model="new_password" type="password" class="shadow-sm focus:ring-base-500 focus:border-base-500 block w-full sm:text-sm border-gray-300 rounded-md h-10" :placeholder="loc.new_password" />
      <small v-if="error !== ''" class="text-red-600 text-xs">{{ error }}</small>
    </div>
    <div class="text-sm text-gray-500">
      <p class="font-medium text-gray-700 mb-1">{{ loc.security_guidelines }}</p>
      <p>{{ loc.at_least_eight_characters }}</p>
      <p>{{ loc.at_least_one_letter }}</p>
      <p>{{ loc.at_least_one_different_character }}</p>
      <p>{{ loc.no_spaces }}</p>
    </div>
    <div>
      <input v-model="repeat_password" type="password" class="shadow-sm focus:ring-base-500 focus:border-base-500 block w-full sm:text-sm border-gray-300 rounded-md h-10" :placeholder="loc.repeat_password" />
    </div>
    <div>
      <button @click="reset_password" :disabled="!isValidForm" type="button" :class="`${!isValidForm ? 'bg-opacity-50 hover:bg-opacity-50' : ''} w-full text-center px-3 py-3 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-base-600 hover:bg-base-500 animation duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base-500`">
        {{loc.change_password}}
      </button>
    </div>
    <div v-if="error === '' && !isValidForm">
      <small class="text-red-600 text-xs">{{ loc.err_passwords_do_not_match }}</small>
    </div>
  </section>
</template>

<script>
import {mapMutations} from "vuex";

export default {
name: "changePassword",
  data() {
    return {
      current_password: '',
      new_password: '',
      repeat_password: '',
      error: '',
    }
  },
  watch: {
    new_password(val) {
      this.new_password = val.trim();
      this.validatePassword(val);
    },
     repeat_password(val) {
      this.repeat_password = val.trim();
    }
  },
  computed: {
    isValidForm() {
      return this.error === '' && this.new_password === this.repeat_password;
    }
  },
  methods: {
   ...mapMutations('ui', {
      set_error: 'set_error',
      set_success: 'set_success'
    }),
    validatePassword(password) {
      // validation rules for password
      const minLength = 8;
      const hasLetter = /[a-zA-Z]/.test(password);
      const hasSpecialChar = /[!@#$%^&*]/.test(password);

      if (password.length < minLength) {
        this.error = this.loc.err_min_length;
      } else if (!hasLetter) {
        this.error = this.loc.err_has_letter;
      } else if (!hasSpecialChar) {
        this.error = this.loc.err_has_special_char;
      } else {
        // password is valid
        this.error = "";
      }
    },
    async reset_password() {
      try {
        let res = await this.axios.post('v2/change-password/', {
          old_password: this.current_password,
          new_password: this.new_password
        })
        this.$emit('close');
      } catch (e) {
        this.set_error(e?.response?.data?.Error)
      }
    }
  }
}
</script>

<style scoped>

</style>